<script setup lang="ts">
import type { SortDirection } from '@rialtic/types'

defineProps<{
  align?: 'start' | 'center' | 'end' | 'left' | 'right'
  hideSort?: boolean
  sort?: SortDirection
}>()

const emit = defineEmits<{
  click: []
}>()
</script>

<template>
  <div
    class="text-xs whitespace-nowrap text-neutral-800"
    :class="{ 'justify-center': align === 'center' }"
  >
    <button
      class="flex flex-1 items-center truncate px-2 py-2 text-xs font-light whitespace-nowrap text-neutral-800 outline-none lg:px-3"
      :class="{
        'flex-row-reverse': align === 'end' || align === 'right',
      }"
      aria-label="table sort"
      type="button"
      @click="emit('click')"
    >
      <slot />

      <div v-if="!hideSort" class="px-2">
        <i
          class="i-ph-arrow-up-bold block h-3 w-3 transition"
          :class="{
            'rotate-180': sort === 'desc',
            'rotate-90 opacity-0': !sort,
          }"
        />
      </div>
    </button>
  </div>
</template>
